import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
  constructor() { }

  static moduleAccess = []
  static storeAccess = []
  isLoggedIn() {
    const token = localStorage.getItem('token');
    if (!token || token === 'null') {
      return false;
    } else {
      return true;
    }
  }

  isAdmin() {
    const admin = localStorage.getItem('admin');
    if (!admin || admin === 'null' || admin === '0') {
      return false;
    } else {
      return true;
    }
  }

  getUserName() {
    const admin = localStorage.getItem('name');
    if (!admin || admin === 'null' || admin === '0') {
      return false;
    } else {
      return admin;
    }
  }

  login(token, admin, name, id) {
    localStorage.setItem('token', token);
    localStorage.setItem('admin', admin);
    localStorage.setItem('name', name);
    localStorage.setItem('uid', id);
  }

  logout() {
    AuthService.moduleAccess = [];
    localStorage.clear();
  }

  getToken() {
    if (this.isLoggedIn()) {
      return localStorage.getItem('token');
    }
    return '0';
  }

  getUserId() {
    if (this.isLoggedIn()) {
      return localStorage.getItem('uid');
    }
    return '0';
  }

  async setModules(modules) {
    if (modules)
      AuthService.moduleAccess = modules
  }

  setUserStores(stores){
    AuthService.storeAccess = stores;
  }


  async getModules() {

    return AuthService.moduleAccess;
  }

  async getUserStores() {
    return AuthService.storeAccess;
  }

  authorizaResource(resource: string, action: string) {
    if (action == "modify") {
      return AuthService.moduleAccess.find(x => x.type == resource).modify == 1
    } else {
      return AuthService.moduleAccess.find(x => x.type == resource).view == 1
    }
  }

}
